<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>内容管理</el-breadcrumb-item>
          <el-breadcrumb-item>反馈列表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="admin_main_block admin_m15">
      <div class="header-form">
        <div class="admin_main_block_left">
          <el-form>
            <el-form-item label="问题类型">
              <el-select
                v-model="where.deviceType"
                placeholder="请选择"
                clearable
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="开启" value="1"></el-option>
                <el-option label="关闭" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否处理">
              <el-select
                v-model="where.deviceType"
                placeholder="请选择"
                clearable
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关键字">
              <el-input
                v-model="where.keyWord"
                placeholder="请输入姓名/门店/交易编号"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="日期">
              <el-date-picker
                v-model="date"
                @change="orderDate" @input="immediUpdate"
                type="daterange"
                range-separator="~"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                                start-placeholder="起始日期"
                end-placeholder="截止日期"
              >
                <i class="el-icon-date"></i>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" plain icon="el-icon-search" @click="listData()"
                >条件筛选</el-button
              >
            </el-form-item>
          </el-form>
        </div>

        <div class="admin_main_block_right">
          <el-row :gutter="20">
            <el-col :span="12"> </el-col>
            <el-col :span="12"> </el-col>
          </el-row>
        </div>
      </div>
      <div class="admin_table_main">
        <el-table
          :data="list"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.1)"
          @selection-change="handleSelectionChange"
        >
          <el-table-column prop="deviceType" label="版本号"></el-table-column>
          <el-table-column prop="deviceType" label="来源"></el-table-column>
          <el-table-column prop="deviceType" label="操作系统"></el-table-column>
          <el-table-column prop="deviceType" label="问题类型"></el-table-column>
          <el-table-column prop="deviceType" label="会员ID"></el-table-column>
          <el-table-column
            prop="deviceType"
            label="问题/意见描述"
          ></el-table-column>
          <el-table-column prop="deviceType" label="联系方式"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                v-if="true"
                class="el-icon-s-shop"
                size="medium"
                @click="edit(scope.row.id)"
                >处理</el-button
              >
              <el-button type="text" v-else class="el-icon-s-shop" size="medium"
                >已处理</el-button
              >
            </template>
          </el-table-column>
        </el-table>

        <div class="admin_table_main_pagination">
          <el-pagination
            @current-change="currentChange"
            @size-change="handleSizeChange"
            background
            :total="totalData"
            :page-size="pageSize"
            :current-page="currentPage"
            :page-sizes="[10, 20, 50, 100]"
            layout="sizes, prev, pager, next, total, jumper"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      list: [],
      date: "",
      row: {},
      loading: false,
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      selectId: "",
      where: {
        page: 0,
        limit: 0,
      },
    };
  },
  methods: {
    listData() {
      this.loading = true;
      this.where.page = this.currentPage;
      this.where.limit = this.pageSize;
      this.$post(this.$api.deviceList, this.where).then((res) => {
        this.totalData = res.data.total;
        this.list = res.data.data;
        this.loading = false;
      });
    },
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v.id);
      });
      this.selectId = ids.join(",");
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
    orderDate(obj) {
      if (obj) {
        this.where.startDate = obj[0];
        this.where.endDate = obj[1];
      }
    },
    immediUpdate(e) {
      this.$nextTick(() => {
        console.log(e)
        if(e==null){
          this.where.startDate = '';
          this.where.endDate = '';
        }
      });
    },
    exportData() {
      // console.log("exportData");
    },
    edit() {
      // console.log("进行更改状态");
    },
  },
  created() {
    // this.listData();
  },
};
</script>
<style lang="scss" scoped>
.endDate {
  color: #c0c4cc;
}
.el-image {
  height: 100px;
  width: 100px;
}
.el-select {
  width: 120px;
}
</style>
